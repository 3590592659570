var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"punish-time-wrapper"},[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'punishTime',
        'settingWrapper': {
          value: 0,
          hasFieldButton: true,
          customiseValue: _vm.StepUnit.Hour
        },
      }
    }}}),(_vm.punishTime > _vm.StepUnit.Day * 10)?_c('a-alert',{attrs:{"type":"error","show-icon":"","message":_vm.$t('field_punish_time_max_value_alert_message')}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }