



























import { InputSetups } from "@/mixins/input-setups";

import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    TimeUnitInput
  },
  data() {
    return {
      StepUnit
    }
  }
})
export default class PunishTimeWrapper extends Mixins(InputSetups) {
  @VModel() punishTime!: number
}
