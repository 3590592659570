










































import PunishMessage from "@/components/Modules/punish-actions/components/PunishMessage.vue";
import { InputSetups } from "@/mixins/input-setups";
import PunishTimeWrapper from "@/components/Modules/punish-actions/components/PunishTimeWrapper.vue";
import { MuteMediaAction } from "@/includes/logic/Modules/models/PunishActions";

import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    PunishTimeWrapper,
    PunishMessage,
    NestedContent,
    TimeUnitInput,
    SwitchInput
  }
})
export default class MuteMediaActionView extends Mixins(InputSetups) {
  @VModel() model!: MuteMediaAction
}
